import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {
  @Input() inputClass: string[] = [];
  @Input() inputLabel: string = '';
  @Input() name: string = '';
  @Input() inputPlaceHolder: string = '';
}
