import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../auth.service';
import {HttpClient} from '@angular/common/http';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {ButtonComponent} from '../button/button.component';
import {Router} from '@angular/router';
import {Calendar} from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {CalendarOptions, FullCalendarComponent} from '@fullcalendar/angular';
import {TextComponent} from '../text/text.component';
import {RadialMenuComponent} from '../radial-menu/radial-menu.component';
import {SpinnerService} from '../spinner/spinner.service';
import {InputComponent} from '../input/input.component';
import {stringIsEmpty, getByValue} from '../helper.service';
import {CheckboxComponent} from '../checkbox/checkbox.component';
import {SelectboxComponent} from '../selectbox/selectbox.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @ViewChild('checkbox', {read: ElementRef, static: true})
  checkbox: CheckboxComponent;

  // @ViewChild('selectbox', {read: ElementRef, static: true})
  // selectbox: SelectboxComponent

  @ViewChild('dateContentCalendar') calendarComponent: FullCalendarComponent;

  @ViewChild('dateBegin', {read: ElementRef, static: true})
  dateBeginTextComponent: TextComponent;

  @ViewChild('dateEnd', {read: ElementRef, static: true})
  dateEndTextComponent: TextComponent;

  @ViewChild('buttonDownload', {read: ElementRef, static: true})
  buttonDownloadComponent: ButtonComponent;

  @ViewChild('buttonGetTimes', {read: ElementRef, static: true})
  buttonGetTimesComponent: ButtonComponent;

  @ViewChild('radialMenu', {read: ElementRef, static: true})
  radialMenuComponent: RadialMenuComponent;

  @ViewChild('adminEmail', {read: ElementRef, static: true})
  inputComponent: InputComponent;

  userName: string = '';
  email: string = '';
  timesObject;
  timesShown = false;
  selectedFistDate: boolean = true;
  startDate: string = '';
  endDate: string = '';
  adminMail: string = '';
  abbreviation: string = '';
  customer: string = '';
  projectNumber: string = '';
  template: string = '';
  linkForProjectTimes: string = '';
  token: string = '';
  disabled: boolean = true;
  isValid: boolean = true;
  exporting: boolean = false;
  radialMenu: Object;
  times = [];
  adminAccount: boolean = false;
  domElements: any;
  domStartDate: any;
  domEndDate: any;
  advanced: boolean = false;
  advancedSelection: string = '';

  @Input() name: string = this.authService.getName() || localStorage.getItem('username');
  @Input() userImg: string = this.authService.getImageUrl() || localStorage.getItem('img');
  @Input() iconLogoutSrc: string = '../assets/svg/icon-logout.svg';
  @Input() iconCheckBoxFilled: string = '../assets/svg/icon-check-box-filled.svg';
  @Input() iconCheckBoxOutlined: string = '../assets/svg/icon-check-box-outlined.svg';
  @Input() iconDownlaod: string = '../assets/svg/icon-download.svg';
  projectDataCheckBox = this.iconCheckBoxOutlined;
  calendarCheckBox = this.iconCheckBoxOutlined;


  form = new FormGroup({
    date: new FormControl('', Validators.required),
    projectNumber: new FormControl('', Validators.required),
    customer: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required),
    template: new FormControl('', Validators.required),
    data: new FormControl('', Validators.required),
    abbreviation: new FormControl('', Validators.required),
  });

  admins = [
    'eugen.haufler@bcmsolutions.de',
    'sven.trippner@bcmsolutions.de',
    'manuela.lorenz@bcmsolutions.de',
    'raymond.dormien@bcmsolutions.de',
    'sven.greve@bcmsolutions.de',
    'sebastian.spaeder@bcmsolutions.de',
    'michael.gross@bcmsolutions.de'
  ]

  mapMonth = new Map<String, String>([
    ['01', 'January'],
    ['02', 'February'],
    ['03', 'March'],
    ['04', 'April'],
    ['05', 'May'],
    ['06', 'June'],
    ['07', 'July'],
    ['08', 'August'],
    ['09', 'September'],
    ['10', 'October'],
    ['11', 'November'],
    ['12', 'December'],
  ]);

  mapTemplate = new Map<String, String>([
    ['1', '7895,7894'],
    ['2', '9066,9065']
  ])

  constructor(public loaderService: SpinnerService, private authService: AuthService, private http: HttpClient, private router: Router, public elRef: ElementRef) {
    const name = Calendar.name;
  }


  ngOnInit(): void {
    this.disableDownloadButton()
    this.domElements = this.elRef.nativeElement.querySelectorAll('.todo__icon');
    this.userName = localStorage.getItem('username');
    this.email = localStorage.getItem('email');
    this.abbreviation = localStorage.getItem('abbreviation');
    this.customer = localStorage.getItem('customer');
    this.projectNumber = localStorage.getItem('projectNumber');
    this.token = localStorage.getItem('id_token');
    this.template = localStorage.getItem('template');
    this.radialMenu = this.radialMenuComponent['nativeElement'].childNodes[0].childNodes[1];
    this.buttonDownloadComponent['nativeElement'].classList.add('disabled-button');
    this.buttonGetTimesComponent['nativeElement'].classList.add('disabled-button');
    this.domStartDate = this.dateBeginTextComponent['nativeElement'];
    this.domEndDate = this.dateEndTextComponent['nativeElement'];
    const domElementLiRadialMenu = this.radialMenu['childNodes'];

    if (this.email === "johannes.flor@bcmsolutions.de") {
      this.userImg = "./assets/img/bisarflor.jpeg";
    }

    domElementLiRadialMenu.forEach(element => {
      const domElementA = element.querySelector('.radial-menu__a');
      if (domElementA.innerText === this.template) domElementA.classList.add('radial-menu__a--active');
    });

    this.admins.forEach(email => {
      if (email === this.email) this.adminAccount = true;
    });

    this.proofProjectInputIsFilled();
    this.inactivityTime();
  }

  onCheckedState(event) {
    this.advanced = event;
  }

  onSelectionDone(event) {
    this.advancedSelection  = event;
  }

  switchDates() {
    let tmpDate = this.startDate;
    this.startDate = this.endDate;
    this.endDate = tmpDate;

    let tmpDomDate = this.domStartDate.innerText;
    this.domStartDate.innerText = this.domEndDate.innerText;
    this.domEndDate.innerText = tmpDomDate;
  }

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin, interactionPlugin],
    selectable: true,
    headerToolbar: {
      start: 'title',
      center: '',
      end: 'prev,next',
    },
    footerToolbar: {
      start: '',
      center: '',
    },

    dateClick: event => {
      const arrayDays = document.querySelectorAll('.fc-daygrid-day-frame');
      let currentDate = event['dateStr'];

      if (this.selectedFistDate) {
        this.resetCheckBoxCalendar();
        this.enableDownloadButton();
        this.startDate = currentDate;
        this.selectedFistDate = !this.selectedFistDate;
        if (!stringIsEmpty(this.endDate)) {
          this.endDate = '';
          this.domEndDate.innerText = 'DD.MM.YYYY';
        }

        const currentDateDom = this.getDomElementOfCurrentDate(currentDate);
        this.blockDate(currentDateDom);
        this.domStartDate.innerText = this.convertToEuDate(currentDate);
        this.clearColoredDates(arrayDays);

        return;
      } else {
        if (currentDate === this.startDate) {
          alert('you cannot select the same date twice chose another!');
          return;
        }

        if (this.getDayMonthYear(currentDate, 'month') !== this.getDayMonthYear(this.startDate, 'month')) {
          alert('you can only select the date within the same month\n' + this.mapMonth.get(this.getDayMonthYear(this.startDate, 'month')));
          return;
        }

        this.endDate = currentDate;
        this.selectedFistDate = !this.selectedFistDate;

        const currentDateDom = this.getDomElementOfCurrentDate(this.startDate);
        this.domEndDate.innerText = this.convertToEuDate(currentDate);

        this.unblockDate(currentDateDom);

        if (this.endDate < this.startDate) this.switchDates();

        const dayStart = this.getDayMonthYear(this.startDate, 'day');
        const dayEnd = this.getDayMonthYear(this.endDate, 'day');
        const month = this.getDayMonthYear(this.startDate, 'month');
        this.coloringDates(arrayDays, dayStart, dayEnd, month);
        this.setCheckBoxCalendar()
      }

      this.enableDownloadButton();
    },
  };

/**
 * convert date to Eu date
 * @param {string} YYYY-MM-DD
 * @returns {string} DD.MMYYY
 */
  convertToEuDate = (date: string) => {
    date = date.split("-")[2] + "." + date.split("-")[1] + "." + date.split("-")[0];
    return date;
  };


  /**
   *
   * @param currentDate as YYYY-MM-DD
   * @returns domElement
   */
  getDomElementOfCurrentDate(currentDate: string) {
    const str = '"';
    currentDate = str + currentDate + str + ']';
    return document.querySelector('[data-date=' + currentDate);

  }

  blockDate(dateDom) {
    dateDom.classList.add('date-blocked');
  }

  unblockDate(dateDom) {
    dateDom.classList.remove('date-blocked');
  }

  blockDatesOutOfMonth(arrayDays, currentMonth): void {
    arrayDays.forEach(day => {
      let month = day.querySelector('a').getAttribute('aria-label').split(' ')[0];
      if (month != currentMonth) {
        day.style.cursor = 'not-allowed';
      }
    });
  }

  unBlockDatesOutOfMonth(arrayDays): void {
    arrayDays.forEach(day => {
      if (day.style.cursor === 'not-allowed') {
        day.style.cursor = 'grab';
      }
    });
  }

  getDayMonthYear(date, col) {

    let result: string = '';
    if (col === 'day') result = date.split('-')[2];
    if (col === 'month') result = date.split('-')[1];
    if (col === 'year') result = date.split('-')[0];

    return result;
  }

  coloringDates(arrayDays, dayStart, dayEnd, month): void {
    arrayDays.forEach(day => {
      const currentDay = parseInt(
        day.querySelector('a').getAttribute('aria-label').split(' ')[1]
      );

      const currentMonth = getByValue(this.mapMonth, day.querySelector('a').getAttribute('aria-label').split(' ')[0])

      if (
        currentDay >= parseInt(dayStart) &&
        currentDay <= parseInt(dayEnd) &&
        currentMonth === month
      ) {
        day.classList.add('active-day');
      }
    });
  }

  clearColoredDates(arrayDays): void {
    arrayDays.forEach(day => {
      if (day.classList[2] === 'active-day') {
        day.classList.remove('active-day');
      }
    });
  }

  setInputName(value): void {
    this.userName = value;
    localStorage.setItem('username', value);
    this.enableDownloadButton();
  }

  setInputAdminMail(value): void {
    this.adminMail = value;
  }

  setInputAbbreviation(value): void {
    this.abbreviation = value;
    localStorage.setItem('abbreviation', value);
    this.enableDownloadButton();
  }

  setInputCustomerName(value): void {
    this.customer = value;
    localStorage.setItem('customer', value);
    this.enableDownloadButton();
  }

  setInputProjectNumber(value): void {
    this.projectNumber = value;
    localStorage.setItem('projectNumber', value);
    this.enableDownloadButton();
  }

  proofProjectInputIsFilled(): void {
    this.enableDownloadButton();
  }

  resetCheckBoxProjectData(): void {
    this.projectDataCheckBox = this.iconCheckBoxOutlined;
  }

  setCheckBoxProjectData(): void {

    this.projectDataCheckBox = this.iconCheckBoxFilled;
  }

  setCheckBoxCalendar(): void {
    this.calendarCheckBox = this.iconCheckBoxFilled;
  }

  resetCheckBoxCalendar(): void {
    this.calendarCheckBox = this.iconCheckBoxOutlined;
  }

  /**
   * check every input field, and checkbox. On condition it will enable
   * the download button to be clicable
   * @returns
   */
  enableDownloadButton(): void {
    const domElements = document.querySelectorAll('.todo__icon');
    const checkBoxProjectData = domElements[0];
    const checkBoxCalendar = domElements[1];

    if (
      !(stringIsEmpty(this.userName)) &&
      !(stringIsEmpty(this.abbreviation)) &&
      !(stringIsEmpty(this.customer)) &&
      !(stringIsEmpty(this.projectNumber)) &&
      !(stringIsEmpty(localStorage.getItem('template')))
    ) {
      this.setCheckBoxProjectData();

      if (
        this.calendarCheckBox === this.iconCheckBoxFilled &&
        this.projectDataCheckBox === this.iconCheckBoxFilled
      ) {
        this.buttonDownloadComponent['nativeElement'].classList.remove('disabled-button');
        this.buttonGetTimesComponent['nativeElement'].classList.remove('disabled-button');
        return;
      }
    } else {
      this.resetCheckBoxProjectData();
    }
    this.disableDownloadButton();
  }

  disableDownloadButton() {
    setTimeout(() => {
      this.buttonDownloadComponent['nativeElement'].classList.add('disabled-button');
      this.buttonGetTimesComponent['nativeElement'].classList.add('disabled-button');
    }, 200);

  }

  logout(): void {
    localStorage.setItem('loggedIn', 'false');
    localStorage.setItem('username', '');
    localStorage.setItem('img', '');
    localStorage.setItem('email', '');
    localStorage.setItem('access_token', '');
    localStorage.setItem('id_token', '');
    location.pathname = "/login";
  }

  inactivityTime(): void {
    let time;
    window.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onkeydown = resetTimer;
    document.onkeyup = resetTimer;

    const logoutUser = () => {
      if (localStorage.getItem('loggedIn') && location.href.indexOf('home') != -1) this.logout();
    };

    function resetTimer(): void {
      if (location.href.indexOf('home') != -1) {
        clearTimeout(time);
        time = setTimeout(logoutUser, 300000);
      }
    }
  };

  showTimes() {
    this.timesShown = !this.timesShown;

    if ( this.timesShown === true ) {
      const urlProd: string = 'https://europe-west1-projektbericht-tool.cloudfunctions.net/getTimes';
      const urlDev: string = 'http://127.0.0.1:5001/projektbericht-tool/europe-west1/getTimes';

      let dates = {
        startDate: this.startDate,
        endDate: this.endDate,
      };
  
      let template;
      if (this.advanced) {
        template = this.advancedSelection;
      } else {
        template = this.mapTemplate.get(localStorage.getItem('template'));
      }
  
      const data = {
        dates: dates,
        projectNumber: this.projectNumber,
        customer: this.customer,
        name: this.userName,
        template: template,
        abbreviation: this.abbreviation,
        token: localStorage.getItem('access_token'),
        advanced: this.advanced
      };
  
      if (this.adminAccount && this.adminMail.length !== 0) {
        data['email'] = this.adminMail;
      }
      
      this.http
      .post(urlProd, data)
      .subscribe((response: any) => {
        let times = response.attendances
        this.timesObject = response;
        this.times = times.slice(1);

      });

    }
  }

  onSubmit(): void {

    if( this.timesShown ) {
      let entrys = this.domElements = this.elRef.nativeElement.querySelectorAll('.timeEntrys');
      let arrayPos = 1;
      entrys.forEach(element => {
        let hour = parseInt(element.querySelector('.hour').querySelector('input').value);
        let min = parseInt(element.querySelector('.minute').querySelector('input').value);
        let hourR = parseInt(element.querySelector('.hourRemote').querySelector('input').value);
        let minR = parseInt(element.querySelector('.minuteRemote').querySelector('input').value);
        let comment = element.querySelector('.comment').querySelector('input').value;
        if ( min > 59 || minR > 59 || hour > 10 || hourR > 10 ) {
          alert("Max Werte beachten!!!!!!")
          return;
        }
        let time = 0;
        let timeR = 0;
        this.timesObject.attendances[arrayPos].comment = comment;
        if ( min > 0 ) {
          time = min / 60;
          if ( hour > 0 ) {
            time += hour
          }
        } else if (hour > 0) {
          time = hour;
        }
        
        if ( time > 0) {
         
          let totalT = parseInt(this.timesObject.totalTime);
           if ( this.timesObject.attendances[arrayPos].time != "" ) {
            let temp = parseInt(this.timesObject.attendances[arrayPos].time)
            let total = temp + time
            let tempTotal = totalT + time;
            this.timesObject.attendances[arrayPos].time = total.toFixed(2).toString();
            this.timesObject.totalTime = tempTotal.toFixed(2).toString()
           } else {
            this.timesObject.attendances[arrayPos].time = time.toFixed(2).toString()
            let tempTotal = totalT + time;
            this.timesObject.totalTime = tempTotal.toFixed(2).toString() 
          }
         
        }
        if ( minR > 0 ) {
          timeR = minR / 60;
          if ( hourR > 0 ) {
            timeR += hourR
          }
        } else if (hourR > 0) {
          timeR = hourR
        }

        if (timeR > 0) {
          let totalT = parseInt(this.timesObject.totalTimeRemote);
          if ( this.timesObject.attendances[arrayPos].timeRemote != "" ) {
            let temp = parseInt(this.timesObject.attendances[arrayPos].timeRemote)
            let total = temp + timeR
            let tempTotal = totalT + timeR;
            this.timesObject.attendances[arrayPos].timeRemote = total.toFixed(2).toString();
            this.timesObject.totalTimeRemote = tempTotal.toFixed(2).toString()
           } else {
            this.timesObject.attendances[arrayPos].timeRemote = timeR.toFixed(2).toString()
            let tempTotal = totalT + timeR;
            this.timesObject.totalTimeRemote = tempTotal.toFixed(2).toString()
           }
        }
        
        arrayPos++;
      });
      let dates = {
        startDate: this.startDate,
        endDate: this.endDate,
      };
  
      let template;
      if (this.advanced) {
        template = this.advancedSelection;
      } else {
        template = this.mapTemplate.get(localStorage.getItem('template'));
      }
      const sideData = {
        dates: dates,
        projectNumber: this.projectNumber,
        customer: this.customer,
        name: this.userName,
        template: template,
        abbreviation: this.abbreviation,
        token: localStorage.getItem('access_token'),
        advanced: this.advanced
      };
      const data = {
        token: localStorage.getItem('access_token'),
        data: this.timesObject,
        sideData: sideData
      };
  
      if (this.adminAccount && this.adminMail.length !== 0) {
        data['email'] = this.adminMail;
      }

      let month: string = (new Date(this.startDate).getMonth() + 1).toString();
      const year: string = new Date(this.startDate).getFullYear().toString().substring(2);
      const abbreviation: string = this.abbreviation;
  
      parseInt(month) < 10 && (month = '0' + month);
  
      const pdfFilename = 'bcm-projektbericht-' + abbreviation + '-' +
        year +
        month +
        '.pdf';
  
      const urlProd: string = 'https://europe-west1-projektbericht-tool.cloudfunctions.net/createPdfCustom';
      const urlDev: string = 'http://127.0.0.1:5001/projektbericht-tool/europe-west1/createPdfCustom'

      this.http
        .post(urlProd, data, {
          responseType: 'blob' as 'json',
          observe: 'response',
        })
        .subscribe((response: any) => {
          let fileName = pdfFilename;
  
          const disposition = response.headers.get('content-disposition');
          if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, '');
            }
          }
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response.body);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', fileName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        });

    } else {
      let dates = {
        startDate: this.startDate,
        endDate: this.endDate,
      };
  
      let template;
      if (this.advanced) {
        template = this.advancedSelection;
      } else {
        template = this.mapTemplate.get(localStorage.getItem('template'));
      }
  
      const data = {
        dates: dates,
        projectNumber: this.projectNumber,
        customer: this.customer,
        name: this.userName,
        template: template,
        abbreviation: this.abbreviation,
        token: localStorage.getItem('access_token'),
        advanced: this.advanced
      };
  
      if (this.adminAccount && this.adminMail.length !== 0) {
        data['email'] = this.adminMail;
      }
  
      let month: string = (new Date(data.dates.startDate).getMonth() + 1).toString();
      const year: string = new Date(data.dates.startDate).getFullYear().toString().substring(2);
      const abbreviation: string = data.abbreviation;
  
      parseInt(month) < 10 && (month = '0' + month);
  
      const pdfFilename = 'bcm-projektbericht-' + abbreviation + '-' +
        year +
        month +
        '.pdf';
  
      const urlProd: string = 'https://europe-west1-projektbericht-tool.cloudfunctions.net/createPdf';
      const urlDev: string = 'http://127.0.0.1:5001/projektbericht-tool/europe-west1/createPdf'
  
      this.http
        .post(urlProd, data, {
          responseType: 'blob' as 'json',
          observe: 'response',
        })
        .subscribe((response: any) => {
          let fileName = pdfFilename;
  
          const disposition = response.headers.get('content-disposition');
          if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = matches[1].replace(/['"]/g, '');
            }
          }
          const dataType = response.type;
          const binaryData = [];
          binaryData.push(response.body);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          downloadLink.setAttribute('download', fileName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        });
    }
    
  }
}
