
export function stringIsEmpty(string: string) {
    if (string) return false;
    return true;
}

export function getByValue(map, searchValue) {
    for (let [key, value] of map.entries()) {
        if (value === searchValue)
            return key;
    }
}
