import {Component, OnInit, ViewChild, ElementRef, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../auth.service';
import {ButtonComponent} from '../button/button.component';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  title: string = 'loginGoogle';
  CLIENT_ID = '11112838263-4aie9ceov01ofneh7e368d1ogj5kf98h.apps.googleusercontent.com';
  API_KEY = 'AIzaSyCQ2QmWembX_g-3zfUXx4Qh6YJN0bv_8jg';
  DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/people/v1/rest';
  SCOPES = 'profile email';
  userEmail: string;
  userName: string;
  userImg: string;
  tokenClient;
  auth2: any;

  @ViewChild('buttonElementRef', {read: ElementRef, static: true})
  buttonElementRef: ButtonComponent;

  constructor(private router: Router, private authService: AuthService, private zone: NgZone) { }

  ngOnInit(): void {
    this.gapiLoaded();
    this.gisLoaded();
  }


  gapiLoaded() {
    gapi.load('client', async () => {
      await gapi.client.init({
        apiKey: this.API_KEY,
        discoveryDocs: [this.DISCOVERY_DOC],
      });
    });
  }

  gisLoaded() {
    this.tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: this.CLIENT_ID,
      scope: this.SCOPES,
      callback: async (resp) => {
        if (resp.error !== undefined) {
          console.log("error: ", resp)
          throw (resp);
        }

        const email = await gapi.client.request({
          'path': 'https://people.googleapis.com/v1/people/me?requestMask.includeField=person.emailAddresses',
        })

        const name = await gapi.client.request({
          'path': 'https://people.googleapis.com/v1/people/me?requestMask.includeField=person.names',
        })

        const img = await gapi.client.request({
          'path': 'https://people.googleapis.com/v1/people/me?requestMask.includeField=person.photos',
        })

        const userEmail = email.result['emailAddresses'][0].value;
        const userName = name.result['names'][0].displayName;
        const userImg = img.result['photos'][0].url;

        const tokenObj = gapi.client.getToken();

        const accessToken = tokenObj['access_token'];

        const idToken = await this.getIdToken();

        const profile = {
          userName: userName,
          userImg: userImg,
          userEmail: userEmail,
          accessToken: accessToken,
          idToken: idToken
        }

        this.authService.login(profile);
        localStorage.setItem('img', this.authService.getImageUrl());
        localStorage.setItem('username', this.authService.getName());
        localStorage.setItem('email', this.authService.getEmail());
        localStorage.setItem('access_token', this.authService.getAccessToken());
        localStorage.setItem('id_token', this.authService.getIdToken());

        this.zone.run(() => {
          this.router.navigate(['/home']);
        });
      },
    });
  }

  handleAuthClick() {
    if (gapi.client.getToken() === null) {
      this.tokenClient.requestAccessToken({prompt: 'consent'});
    } else {
      this.tokenClient.requestAccessToken({prompt: ''});
    }
  }

  async getIdToken() {
    await gapi.client.init({
      'apiKey': this.API_KEY,
      'discoveryDocs': ['https://people.googleapis.com/$discovery/rest'],
      'clientId': this.CLIENT_ID,
      'scope': 'profile',
    });

    const tokenObj = gapi.client.getToken();
    const id_token = tokenObj['id_token'];

    return id_token;

  }
}
