<div class="log-in-component">
  <div class="main">
    <div class="container row">
      <div class="main__content col-3">
        <app-text [textClass]="'main__sub-title'">Projektbericht</app-text>
        <app-text [textClass]="'main__title'">Export Tool</app-text>
        <ol>
          <app-text [textClass]="'main__list-item'">1. Log in über Google</app-text>
          <app-text [textClass]="'main__list-item'">2. Felder ausfüllen</app-text>
          <app-text [textClass]="'main__list-item'">3. PDF generieren</app-text>
        </ol>
      </div>
      <div class="log-in col-3 row">
        <section class="log-in__content">
          <app-text [textClass]="'log-in__title'">Sign in</app-text>
          <app-button (click)="handleAuthClick()" [buttonClass]="'log-in__button'">
            <app-image
              [imageClass]="'log-in__icon-google'"
              [imageSrc]="'../assets/svg/logo_googleg_48dp.svg'"
              [imageAltName]="'google-btn'"></app-image>
          </app-button>
        </section>
        <app-image
          [imageClass]="'log-in__icon-pdf'"
          [imageSrc]="'../assets/svg/pdfIconSymbol.svg'"
          [imageAltName]="'pdf-icon-symbol'"></app-image>
      </div>
    </div>
  </div>
  <app-image
    [imageClass]="'ringIconUpperLeft'"
    [imageSrc]="'../assets/svg/ringIconUpperLeft.svg'"
    [imageAltName]="'ringIconUpperLeft'"></app-image>
  <app-image
    [imageClass]="'ringIconUpperRight'"
    [imageSrc]="'../assets/svg/ringIconLowerRight.svg'"
    [imageAltName]="'ringIconLowerRight'"></app-image>
</div>
