import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {
  @Output() checkedState: EventEmitter<boolean> = new EventEmitter();
  checked: boolean = false;

  toggleEditable(event) {
    this.checkedState.emit(event.target.checked);
  }

}
