import {Component, Input} from '@angular/core';
import {HomeComponent} from '../home/home.component';

@Component({
  selector: 'app-radial-menu',
  templateUrl: './radial-menu.component.html',
  styleUrls: ['./radial-menu.component.scss'],
})
export class RadialMenuComponent {
  template: string = '';
  @Input() homeComponent: HomeComponent;

  setTemplateInLocalStorage(value): void {
    this.template = value;
    localStorage.setItem('template', value);
  }


  setTemplate(index: number): void {
    index = index + 1;
    const elements = document.querySelectorAll('.radial-menu__a');

    elements.forEach(element => {
      if (element.classList.contains('radial-menu__a--active')) element.classList.remove('radial-menu__a--active');

      const textElement = (element as HTMLElement).querySelector('div[ng-reflect-ng-class="radial-menu__text, radial-menu"]');
      
      const textFieldNumber = textElement.innerHTML;
      
      if (parseInt(textFieldNumber) === index) {
        element.classList.add('radial-menu__a--active');
        this.setTemplateInLocalStorage(textFieldNumber);
        this.homeComponent.enableDownloadButton();
      }
    });


  }
}
