import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
  constructor() {}
  @Input() imageClass: string[] = [];
  @Input() imageSrc: string = '';
  @Input() imageAltName: string = '';
}
