import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {HttpClient} from '@angular/common/http';
@Component({
  selector: 'app-selectbox',
  templateUrl: './selectbox.component.html',
  styleUrls: ['./selectbox.component.scss']
})
export class SelectboxComponent implements OnInit {
  @Output() selectionDone: EventEmitter<boolean> = new EventEmitter();
  
  constructor(private http: HttpClient) {}
  data = [];
  ngOnInit(): void {
      
    const data = {
      token: localStorage.getItem('access_token'),
    };

    const urlProd: string = 'https://europe-west1-projektbericht-tool.cloudfunctions.net/getProjects';
    const urlDev: string = 'http://127.0.0.1:5001/projektbericht-tool/europe-west1/getProjects'

    this.http
      .post(urlProd, data)
      .subscribe((response: any) => {
        console.error(response)
        this.data = response.data
      });
  }

  toggleEditable(event) {
    this.selectionDone.emit(event.target.value)
  }
}
