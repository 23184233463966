import {Component, Input} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SpinnerService} from '../spinner/spinner.service';
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() buttonClass: string[] = [];
  @Input() disabledValue: boolean;
  @Input() isSubmit: boolean = false;


  constructor(public loaderService: SpinnerService) {

  }
}
