<div class="home">
  <div class="dashboard">
    <div class="container-fluid dashboard__container row">
      <div class="col-1 user-col row">
        <div class="user-col__content">
          <app-image [imageClass]="'user-col__profile-img'" [imageSrc]="userImg" [imageAltName]="name">
          </app-image>
          <app-text [textClass]="'user-col__label'">Willkommen</app-text>
          <app-text [textClass]="'user-col__name'">{{ name }}</app-text>
          <app-input *ngIf="adminAccount" #adminEmail [name]="adminMail" type="email" [inputPlaceHolder]="'email'"
            [inputClass]="'user-col__input-email input-material-design--dark'"
            (keyup)="setInputAdminMail($event.target.value)">
          </app-input>
          <app-checkbox (checkedState)="onCheckedState($event)"></app-checkbox>
        </div>
      </div>
      <div class="col-4 dashboard-col">
        <header class="dashboard-col__header container">
          <app-text [textClass]="'dashboard-col__title'">Projektbericht</app-text>
          <app-text [textClass]="'dashboard-col__title dashboard-col__title--bold'">Export Tool</app-text>
        </header>
        <div class="container row">
          <div class="col-3 project-data-col">
            <section class="project-data-col__content">
              <app-text [textClass]="'project-data-col__title'">Projektdaten</app-text>
              <form class="project-data-col__form">
                <div class="project-data-col__inline">
                  <div class="container-fluid">
                    <app-input [name]="userName" type="text" [inputPlaceHolder]="'Name'"
                      [inputClass]="'project-data-col__input input-material-design'"
                      (keyup)="setInputName($event.target.value)"></app-input>
                  </div>
                  <div class="container-fluid">
                    <app-input [name]="abbreviation" type="text" [inputPlaceHolder]="'Kuerzel'"
                      [inputClass]="'project-data-col__input input-material-design'"
                      (keyup)="setInputAbbreviation($event.target.value)"></app-input>
                  </div>
                </div>
                <app-input [name]="customer" type="text" [inputPlaceHolder]="'Kunde'"
                  [inputClass]="'project-data-col__input input-material-design'"
                  (keyup)="setInputCustomerName($event.target.value)"></app-input>
                <app-input [name]="projectNumber" type="text" [inputPlaceHolder]="'Projektnummer'"
                  [inputClass]="'project-data-col__input input-material-design'"
                  (keyup)="setInputProjectNumber($event.target.value)"></app-input>
              </form>
            </section>
            <div class="col-6 radial-menu__col" [ngClass]="{'hide': advanced}">
              <app-radial-menu #radialMenu [homeComponent]="this"></app-radial-menu>
            </div>
            <div class="col-6 advanced-menu__col" [ngClass]="{'hide': !advanced}">
              <app-selectbox (selectionDone)="onSelectionDone($event)"></app-selectbox>
            </div>
          </div>
          <section class="col-3 select-col row">
            <div class="col-6 date-content">
              <div class="col-6 date-content__labels">
                <div class="container date-content__container-labels">
                  <section class="date-content__begin-section">
                    <app-text [textClass]="'date-content__label'">Begin</app-text>
                    <app-text #dateBegin [textClass]="'date-content__date-begin'">
                      DD.MM.YYYY
                    </app-text>
                  </section>
                  <section class="date-content__end-section">
                    <app-text [textClass]="'date-content__label'">End</app-text>
                    <app-text #dateEnd [textClass]="'date-content__date-end'">DD.MM.YYYY</app-text>
                  </section>
                </div>
              </div>
              <div class="col-6 date-content__calendar">
                <full-calendar #dateContentCalendar dateClick="dateClick($event)" [options]="calendarOptions">
                </full-calendar>
              </div>
            </div>
            <div class="col-6 pdf-col">
              <div class="col-6 pdf-col__todo todo">
                <section class="todo__section">
                  <div class="todo__icon">
                    <app-image #checkBoxProjectData [imageClass]="'todo__icon-svg'" [imageSrc]="projectDataCheckBox">
                    </app-image>
                  </div>
                  <app-text [textClass]="'todo__content'">
                    Fülle die
                    <app-text [textClass]="'todo__text--bold'">Projektdaten</app-text>
                    vollständig aus.
                  </app-text>
                </section>
                <section class="todo__section">
                  <div class="todo__icon">
                    <app-image #checkBoxCalendar [imageClass]="'todo__icon-svg'" [imageSrc]="calendarCheckBox">
                    </app-image>
                  </div>
                  <app-text [textClass]="'todo__content'">
                    Gebe ein <app-text [textClass]="'todo__text--bold'">Start </app-text>Datum und
                    ein <app-text [textClass]="'todo__text--bold'">End </app-text>Datum an.
                  </app-text>
                </section>
              </div>
              <section class="col-6 pdf-col__btn-section">
                <app-button [ngClass]="{'disabled-button' : (loaderService.isLoading | async) }"
                #buttonGetTimes (click)="showTimes()" [buttonClass]="'pdf-col__btn-download showTimes'">
                  Zeiten manuell anpassen
                </app-button>
                <div class="timesContainer" *ngIf="timesShown">
                  <div class="info">Stunden Max - Wert liegt bei 10 und Minuten Max - Wert bei 59 </div>
                  <div class="header">
                    <div class="day">Tag</div>
                    <div class="comment">Kommentar</div>
                    <div class="onsite">vor Ort</div>
                    <div class="remote">Remote</div>
                    <div class="hour">Stunden +</div>
                    <div class="minute">Minuten +</div>
                    <div class="hour">Stunden Remote +</div>
                    <div class="minute">Minuten Remote +</div>
                  </div>
                  <div *ngFor="let item of times; index as i" class="timeEntrys">
                    <div class="day">{{item.day}}</div>
                    <div class="comment"><input type="text" placeholder="kommentar anpassen" value="{{item.comment}}"></div>
                    <div class="onsite">{{item.time}}</div>
                    <div class="remote">{{item.timeRemote}}</div>
                    <div class="hour"><input type="number" value="0" placeholder="MAX 10" max="10"></div>
                    <div class="minute"><input type="number" value="0" placeholder="MAX 59" max="59"></div>
                    <div class="hourRemote"><input type="number" value="0" placeholder="MAX 10" max="10"></div>
                    <div class="minuteRemote"><input type="number" value="0" placeholder="MAX 59" max="59"></div>
                  </div>
                </div>
                <app-button [ngClass]="{'disabled-button' : (loaderService.isLoading | async) }" [isSubmit]="true"
                  #buttonDownload (click)="onSubmit()" [buttonClass]="'pdf-col__btn-download'">
                  <app-image  [imageSrc]="iconDownlaod" [imageClass]="'pdf-col__icon-download'" *ngIf="!(loaderService.isLoading |async) ">
                  </app-image>
                  <app-spinner *ngIf="loaderService.isLoading |async "></app-spinner>
                </app-button>
              </section>
            </div>
          </section>
        </div>
      </div>
      <div class="col-1 log-out-col">
        <app-button #logOutButton (click)="logout()" [buttonClass]="'log-out-col__button'">
          <app-image [imageClass]="'log-out-col__icon'" [imageSrc]="iconLogoutSrc">
          </app-image>
        </app-button>
      </div>
    </div>
  </div>
</div>
