export class AuthService {
  loggedIn: boolean = JSON.parse(localStorage.getItem('loggedIn') || 'false');
  googleToken: string = '';
  userName: string = '';
  userImg: string = '';
  userEmail: string = '';
  userId: string = '';
  acessToken: string = '';
  idToken: string = '';

  login(profile) {
    this.loggedIn = true;
    this.userName = profile.userName;
    this.userImg = profile.userImg;
    this.userEmail = profile.userEmail;
    this.acessToken = profile.accessToken;
    this.idToken = profile.idToken;
    this.setState();
  }

  getName() {
    return this.userName;
  }

  getAccessToken() {
    return this.acessToken;
  }

  getState() {
    return this.loggedIn;
  }

  getImageUrl() {
    return this.userImg;
  }

  getEmail() {
    return this.userEmail;
  }

  getIdToken() {
    return this.idToken;
  }

  setState() {
    localStorage.setItem('loggedIn', JSON.stringify(this.loggedIn));
  }
}
