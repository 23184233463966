<div #menu class="radial-menu">
  <app-button [buttonClass]="'radial-menu__button'">+</app-button>
  <ul class="radial-menu__ul">
    <li class="radial-menu__li">
      <div (click)="setTemplate(0)" class="radial-menu__a">
        <span class="radial-menu__span">
          <app-text [textClass]="'radial-menu__text, radial-menu__1'">1</app-text>
        </span>
      </div>
    </li>
    <li class="radial-menu__li">
      <div (click)="setTemplate(1)" class="radial-menu__a">
        <span class="radial-menu__span">
          <app-text [textClass]="'radial-menu__text, radial-menu__2'">2</app-text>
        </span>
      </div>
    </li>
  </ul>
</div>