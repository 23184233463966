import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSelectModule} from '@angular/material/select';
import {AuthService} from './auth.service';
import {AuthGuardService} from './auth-guard.service';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {ReactiveFormsModule} from '@angular/forms';
import {TextComponent} from './text/text.component';
import {ButtonComponent} from './button/button.component';
import {InputComponent} from './input/input.component';
import {ImageComponent} from './image/image.component';
import {FullCalendarModule} from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';
import {RadialMenuComponent} from './radial-menu/radial-menu.component';
import {SpinnerComponent} from './spinner/spinner.component';
import {InterceptorComponent} from './interceptor/interceptor.component';
import { SelectboxComponent } from './selectbox/selectbox.component';
import { CheckboxComponent } from './checkbox/checkbox.component';

FullCalendarModule.registerPlugins([dayGridPlugin, interactionPlugin, momentPlugin]);
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    TextComponent,
    ButtonComponent,
    InputComponent,
    ImageComponent,
    RadialMenuComponent,
    SpinnerComponent,
    InterceptorComponent,
    SelectboxComponent,
    CheckboxComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSelectModule,
    HttpClientModule,
    ReactiveFormsModule,
    FullCalendarModule,
  ],
  providers: [
    {
      provide: AuthService,
    },
    {
      provide: AuthGuardService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorComponent,
      multi: true
    }

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
