<details class="custom-select">
	<summary class="radios">
    
		<input
     type="radio"
     name="item"
     id="default"
     title="Auswählen..."
     checked>
     <input *ngFor="let item of data; index as i"
      type="radio"
      name="item"
      id="item{{i}}"
      title="{{item.attributes.name}}"
      value="{{item.id}}"
      (change)="toggleEditable($event)"
    >
	</summary>
	<ul class="list">
    <li *ngFor="let item of data; index as i">
      <label for="item{{i}}">
        {{item.attributes.name}}
      </label>
    </li>
	</ul>
</details>
